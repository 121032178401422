<template>
  <b-row class="preview-center" no-gutters>
    <b-col>

      <div class="product-box">
        <div v-if="!uploading" class="product-img">
          <img
            v-if="src"
            :src="src"
            class="object-fit-img"
            height="70px"
            width="120px"
            alt
          />
          <img
            v-else
            class="pointer"
            height="50"
            @click="upload"
            width="80"
            src="@/assets/images/playground/ban.svg"
            alt
          />
          <div v-if="src" class="product-hover">
            <ul>
              <li
                v-if="fileToken === null"
                @click="upload"
                class="pointer"
              >
                <button class="btn" type="button">
                  <i class="icon-upload"></i>
                </button>
              </li>
              <li
                @click="deletePhoto()"
                class="pointer"
              >
                <button
                  class="btn"
                  type="button"
                >
                  <i class="icon-trash"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div v-else class="product-img">
          <span class="icon-refresh-size">
            <i class="disabled fa fa-refresh fa-spin"></i>
          </span>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {deleteClubPlaygroundPhoto} from "@api/doinsport/services/playground/playground-photo.api";
import {fromIriReferenceToId} from "@/utils/form";

export default {
  data: () => ({
    fileToken: null,
    uploading: false,
  }),
  props: {
    photo: {
      type: Object,
      default: () => {
      },
    }
  },
  computed: {
    src() {
      return this.fileToken;
    }
  },
  methods: {
    loadPhoto() {
      if (this.photo.photos.length > 0) {
        this.fileToken = this.$filePath(this.photo.photos[0].contentUrl);
      } else {
        this.fileToken = null;
      }
    },
    upload() {
      this.uploadProceed();
    },
    deletePhoto() {
      deleteClubPlaygroundPhoto(this.photo.photos[0].id)
        .then((response) => {
          this.$emit('on:delete-playground-photo');
        })
      ;
    },
    uploadProceed() {
      this.$upload(this.uploaded, {entry: 'playground', url: this.photo['@id']});

      const el = document.getElementById('file-upload-id');

      el.addEventListener('change', () => {
        this.uploading = true;
      });
    },
    uploaded(file) {
      try {
        this.uploading = false;
        this.fileToken = this.$filePath(file.contentUrl);
        this.$emit('on:upload-playground-photo', file);
      } catch (e) {
        this.$flash(null, this.$t('validation.check-file'), 3000);
      }
    },
  },
  mounted() {
    this.loadPhoto();
  }
}
</script>
<style scoped>

.preview-center {
  text-align: center;
}

.product-box .product-img .product-hover {
  height: 65px;
  width: 100%;
}

.icon-refresh-size {
  font-size: 40px;
  color: #0B2772;
}

@media only screen and (max-width: 991px) {
  .preview-center {
    text-align: left;
  }
}
</style>
